import { useEffect, useRef } from "react";

import { useGetUserHashQuery } from "@/redux/services/firebaseService";
import { getAuth } from "firebase/auth";

export const useSeedGoogleTagManagerData = () => {
    const auth = getAuth();
    const userDataInitialized = useRef(false);
    const userHashInitialized = useRef(false);
    const { data: userHashResponse } = useGetUserHashQuery(undefined, { skip: !auth.currentUser });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (userDataInitialized.current) {
                return;
            }

            window.dataLayer.push({
                event: "firebase-user-updated",
                uid: user?.uid,
                email: user?.email,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore for some reason typescript doesn't think this field exists on metadata. It does
                userCreatedAt: parseInt(user?.metadata.createdAt, 10),
                name: user?.displayName,
                phoneNumber: user?.phoneNumber,
                avatarPhoto: user?.photoURL,
            });
            userDataInitialized.current = true;
        });

        return unsubscribe;
    });

    useEffect(() => {
        if (!userHashResponse || userHashInitialized.current) {
            return;
        }
        window.dataLayer.push({
            event: "firebase-user-updated",
            userHash: userHashResponse.user_hash,
        });
    }, [userHashResponse]);
};
