import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { Grid, Paper } from "@mui/material";

import HomePageBackground from "../../assets/images/HomePageBackground.png";
import { SignUpForm } from "../Auth/SignUpForm";

export const SignUpWindow: FC = () => {
    const [searchParams] = useSearchParams();
    const workspaceId = searchParams.get("workspaceId");

    return (
        <>
            <Grid
                container
                sx={{
                    backgroundImage: `url(${HomePageBackground})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    minHeight: "100vh",
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    left: 0,
                }}
                justifyContent="center"
                alignItems={"center"}
                data-testid="sign-up-window"
            >
                <Paper elevation={0}>
                    {workspaceId ? (
                        <SignUpForm onlySignUp redirectUrl={`${workspaceId}/honeygrid`} />
                    ) : (
                        <SignUpForm onlySignUp redirectUrl="/" />
                    )}
                </Paper>
            </Grid>
        </>
    );
};
