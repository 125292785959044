import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
    GetAccessibleGoogleAccountsRequest,
    GetAccessibleGoogleAccountsResponse,
    GetCampaignsForGoogleAccountRequest,
    GetCampaignsForGoogleAccountResponse,
    PushMicrotargetsToGoogleAdsRequest,
    PushMicrotargetsToGoogleAdsResponse,
} from "honeygrid-types";
import { enqueueSnackbar } from "notistack";

import { Builder } from "..";

export const googleAdsApiEndpoints = (
    builder: Builder,
    apiUrl?: string,
    _firestore = getFirestore(),
    functions = getFunctions(),
) => ({
    /**
     * Get a list of all Google Ads accounts for a given connection.
     */
    getAccessibleGoogleAccounts: builder.query<GetAccessibleGoogleAccountsResponse, GetAccessibleGoogleAccountsRequest>(
        {
            queryFn: async ({ workspaceId, connectionId }) => {
                try {
                    const getAccessibleGoogleAccounts = httpsCallableFromURL<
                        GetAccessibleGoogleAccountsRequest,
                        GetAccessibleGoogleAccountsResponse
                    >(functions, `${apiUrl}/api/getAccessibleGoogleAccounts`);

                    const response = await getAccessibleGoogleAccounts({
                        workspaceId,
                        connectionId,
                    });

                    return { data: response.data };
                } catch (e: any) {
                    enqueueSnackbar("Error fetching Google Ads accounts", { variant: "error" });
                    return { error: e.message };
                }
            },
        },
    ),
    /**
     * Get a list of all Google Ads campaigns for a given account.
     */
    getCampaignsForGoogleAccount: builder.query<
        GetCampaignsForGoogleAccountResponse,
        GetCampaignsForGoogleAccountRequest
    >({
        queryFn: async ({ workspaceId, connectionId }) => {
            try {
                const getCampaignsForGoogleAccount = httpsCallableFromURL<
                    GetAccessibleGoogleAccountsRequest,
                    GetAccessibleGoogleAccountsResponse
                >(functions, `${apiUrl}/api/getCampaignsForGoogleAccount`);

                const response = await getCampaignsForGoogleAccount({
                    workspaceId,
                    connectionId,
                });

                return { data: response.data };
            } catch (e: any) {
                enqueueSnackbar("Error fetching Google Ads campaigns", { variant: "error" });
                return { error: e.message };
            }
        },
    }),
    /**
     * Push a list of microtargets to Google Ads.
     */
    syncToGoogleAds: builder.mutation<PushMicrotargetsToGoogleAdsResponse, PushMicrotargetsToGoogleAdsRequest>({
        queryFn: async ({ workspaceId, connectionId, campaignId, cellIds, isFullReplace }) => {
            try {
                const pushMicrotargetsToGoogleAds = httpsCallableFromURL<
                    PushMicrotargetsToGoogleAdsRequest,
                    PushMicrotargetsToGoogleAdsResponse
                >(functions, `${apiUrl}/api/pushMicrotargetsToGoogleAds`);

                const response = await pushMicrotargetsToGoogleAds({
                    workspaceId,
                    connectionId,
                    campaignId,
                    cellIds,
                    isFullReplace,
                });
                enqueueSnackbar("Microtargets synced to Google Ads", { variant: "success" });

                return { data: response.data };
            } catch (e: any) {
                enqueueSnackbar("Error syncing microtargets to Google Ads", { variant: "error" });
                return { error: e.message };
            }
        },
    }),
});
