import { getAuth } from "firebase/auth";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    or,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { Connection } from "honeygrid-types";

import { Collections } from "../collections";
import { connectionConverter } from "./converters";
import { Builder } from "./types";

export const connectionsEndpoints = (
    builder: Builder,
    _apiUrl?: string,
    firestore = getFirestore(),
    auth = getAuth(),
) => ({
    /**
     * A query that retrieves all connections for a given workspace from the Firestore database.
     * @param {{ workspaceId: string }} params The workspace ID to retrieve grids for.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getConnections"]} The RTK Query endpoint for retrieving all connections for a given workspace.
     */
    getConnections: builder.query<Connection[], { workspaceId: string }>({
        queryFn: async ({ workspaceId }) => {
            if (!workspaceId) {
                console.log("No workspaceId provided");
                return { data: [] as Connection[] };
            }

            if (!auth.currentUser) {
                console.log("No user is authenticated");
                return { data: [] as Connection[] };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const connectionsSubcollection = collection(workspaceDoc, Collections.Connections).withConverter(
                connectionConverter,
            );
            const q = query(
                connectionsSubcollection,
                or(
                    where("roles.owner", "array-contains", auth.currentUser.uid),
                    where("roles.editor", "array-contains", auth.currentUser.uid),
                    where("roles.viewer", "array-contains", auth.currentUser.uid),
                ),
            );
            const connectionSnapshot = await getDocs(q);
            const connections: Connection[] = [];
            connectionSnapshot.forEach(doc => {
                connections.push(doc.data());
            });
            return { data: connections };
        },
        providesTags: [Collections.Connections],
    }),
    /**
     * A query that retrieves a single connection from the Firestore database by its ID and workspace ID.
     *
     * @param {{ workspaceId: string; connectionId: string }} params The workspace ID and grid ID to retrieve.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getGridById"]} The RTK Query endpoint for retrieving a single connection by ID and workspace ID.
     */
    getConnectionById: builder.query<Connection, { workspaceId: string; connectionId: string }>({
        queryFn: async ({ workspaceId, connectionId }) => {
            if (!workspaceId || !connectionId) {
                console.log(
                    `Missing either workspaceId or connectionId. workspaceId: ${workspaceId}. connectionId: ${connectionId}`,
                );

                return { data: {} as Connection };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const connectionDoc = doc(workspaceDoc, Collections.Connections, connectionId).withConverter(
                connectionConverter,
            );
            const connection = await getDoc(connectionDoc);
            return { data: connection.data() as Connection };
        },
        providesTags: (_result, _error, { connectionId }) => [{ type: Collections.Connections, connectionId }],
    }),

    /**
     * A query that archives a connection for a given workspace from the Firestore database.
     * @param workspaceId: string The workspace ID that owns this connection.
     * @param connectionId: string The connection ID to archive.
     * @returns {ReturnType<typeof createApi>["endpoints"]["archiveConnection"]} The RTK Query endpoint for deleting a connection in a given workspace.
     */
    deleteConnection: builder.mutation<string, { workspaceId: string; connectionId: Connection["id"] }>({
        queryFn: async ({ workspaceId, connectionId }) => {
            try {
                // Change the status of the connection to archived
                const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
                const connectionDoc = doc(workspaceDoc, Collections.Connections, connectionId);
                await deleteDoc(connectionDoc);
                return { data: "success" };
            } catch (e) {
                if (import.meta.env.MODE === "development") {
                    console.error(e);
                }
                return { error: e };
            }
        },
        invalidatesTags: [Collections.Connections],
    }),
    /**
     * A mutation that updates a connection for a given workspace from the Firestore database.
     * @param workspaceId: string The workspace ID that owns this connection.
     * @param connectionId: string The connection ID to update.
     * @param connection: Partial<Connection> The connection object to update.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateConnection"]} The RTK Query endpoint for updating a connection in a given workspace.
     */
    updateConnection: builder.mutation<
        Connection,
        { workspaceId: string; connectionId: Connection["id"]; connection: Partial<Connection> }
    >({
        queryFn: async ({ workspaceId, connectionId, connection }) => {
            try {
                const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
                const connectionDoc = doc(workspaceDoc, Collections.Connections, connectionId);
                await updateDoc(connectionDoc, connection);
                return { data: connection as Connection };
            } catch (e) {
                if (import.meta.env.MODE === "development") {
                    console.error(e);
                }
                return { error: e };
            }
        },
        invalidatesTags: [Collections.Connections],
    }),
    /**
     * A mutation that creates a connection for a given workspace from the Firestore database.
     * @param workspaceId: string The workspace ID that owns this connection.
     * @param connection: Partial<Connection> The connection object to create.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createConnection"]} The RTK Query endpoint for creating a connection in a given workspace.
     */
    createConnection: builder.mutation<Connection, { workspaceId: string; connection: Partial<Connection> }>({
        queryFn: async ({ workspaceId, connection }) => {
            try {
                const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
                const connectionsSubcollection = collection(workspaceDoc, Collections.Connections).withConverter(
                    connectionConverter,
                );
                const newConnectionDoc = await addDoc(connectionsSubcollection, connection);
                return { data: { ...connection, id: newConnectionDoc.id } as Connection };
            } catch (e) {
                if (import.meta.env.MODE === "development") {
                    console.error(e);
                }
                return { error: e };
            }
        },
        invalidatesTags: [Collections.Connections],
    }),
});
