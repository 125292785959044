import { FC } from "react";

import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";

interface LoadingBackdropProps {
    isOpen: boolean;
}

export const Loading: FC = () => {
    return (
        <Box width={1} height={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography variant="h4">Loading...</Typography>
        </Box>
    );
};

export const LoadingBackdrop: FC<LoadingBackdropProps> = ({ isOpen }) => {
    return (
        <Backdrop
            open={isOpen}
            role="loading-backdrop"
            sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
