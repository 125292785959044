import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";

import { Builder } from "./types";

export const userHashEndpoints = (
    builder: Builder,
    apiUrl?: string,
    _firestore = getFirestore(),
    functions = getFunctions(),
) => ({
    /**
     * A query that retrieves the user hash for a given user for use in the Intercom chat widget.
     */
    getUserHash: builder.query({
        queryFn: async () => {
            const getUserHash = httpsCallableFromURL<undefined, { user_hash: string }>(
                functions,
                `${apiUrl}/api/getIntercomUserHash`,
            );
            let response;
            try {
                response = await getUserHash();
            } catch (error) {
                console.error(error);
                throw new Error("Could not retrieve user hash");
            }
            return response;
        },
    }),
});
