import { FeatureCollection, MultiPolygon, Point, Polygon } from "@turf/helpers";

export type AggregatedDemographics = {
    averages: Record<string, number>;
    sortedCellIds: Record<string, string[]>;
    cells: Record<string, HoneygridAPIH3CellProperties>;
};

export type BoostConfig = {
    percentage: number;
};

export type GeoJSONConversionResult<T> = {
    h3Cells: { h3Index: string; blockPercentageOverlap: number; cellPercentageOverlap: number }[];
    averageConversionTime: number | null;
    metadata: T;
};

export interface CensusBlock {
    type: "Feature";
    properties: {
        STATEFP20: string;
        COUNTYFP20: string;
        TRACTCE20: string;
        BLOCKCE20: string;
        GEOID20: string;
        NAME20: string;
        MTFCC20: string;
        FUNCSTAT20: string;
        ALAND20: number;
        AWATER20: number;
        INTPTLAT20: string;
        INTPTLON20: string;
        HOUSING20: number | null;
        POP20: number | null;
        UR20: string;
        UACE20: string | null;
        UATYPE20: string | null;
    };
    geometry: Polygon | MultiPolygon;
}

export interface CensusBlockData {
    type: "FeatureCollection";
    name: string;
    crs: {
        type: "name";
        properties: {
            name: string;
        };
    };
    features: CensusBlock[];
}

export interface USPSCarrierRoutePolygon {
    geoCode: string;
    geometry: MultiPolygon | Polygon;
    hasArea: true;
    properties: USPSDemographic;
}

export interface USPSCarrierRoutePoint {
    geoCode: string;
    geometry: Point;
    hasArea: false;
    properties: USPSDemographic;
}

export type USPSCarrierRoute = USPSCarrierRoutePolygon | USPSCarrierRoutePoint;

export interface USPSCSVDemographic {
    zipCode: string;
    cRRT: string;
    geoCode: string;
    state: string;
    cityName: string;
    postalCountyName: string;
    businesses: number;
    sFDU: number;
    mFDU: number;
    names: number;
    total: number;
    runningTotal: number;
    distanceinMilesToCenterofCarrierRoute: number;
    driveTimeinMinutestoCenterofCarrierRoute: number;
    homeValue: number;
    income: number;
    age: number;
    percentOwnerOccupied: number;
    percentHouseholdWithChildren: number;
    percentHispanic: number;
    percentSaturation: number;
    possibleTotal: number;
    possibleResidential: number;
}

export interface USPSCSVNewMoversDemographic {
    zipCode: string;
    cRRT: string;
    geoCode: string;
    state: string;
    cityName: string;
    postalCountyName: string;
    sFDU: number;
    mFDU: number;
    names: number;
    phones: number;
    dNCs: number;
    mailedToTotal: number;
    distanceinMilesToCenterofZipCode: number;
    distanceinMilesToCenterofCarrierRoute: number;
    driveTimeinMinutestoCenterofCarrierRoute: number;
    medianHomeValue: number;
    medianIncome: number;
    medianAge: number;
    runningTotal: number;
}

export interface USPSDemographic {
    zipCode: string;
    cRRT: string;
    geoCode: string;
    state: string;
    cityName: string;
    postalCountyName: string;
    businesses: number;
    sFDU: number;
    mFDU: number;
    names: number;
    total: number;
    runningTotal: number;
    distanceinMilesToCenterofCarrierRoute: number;
    driveTimeinMinutestoCenterofCarrierRoute: number;
    homeValue: number;
    income: number;
    age: number;
    percentOwnerOccupied: number;
    percentHouseholdWithChildren: number;
    percentHispanic: number;
    percentSaturation: number;
    possibleTotal: number;
    possibleResidential: number;
    newMoverTotal: number;
    newMoverSFDU: number;
    newMoverMFDU: number;
    newMoverNames: number;
}

export interface H3CellIntermediates<T> {
    blocks: {
        [geoId: string]: {
            blockPercentageOverlap: number;
            cellPercentageOverlap: number;
        };
    };
    properties: {
        [K in keyof T]: {
            geoId: string;
            value: T[K];
        }[];
    };
}

export interface H3CellEntry<Properties> {
    h3CellId: string;
    properties: Properties;
    intermediates: H3CellIntermediates<Properties>;
}

export interface IndexerPipelineProperty<Source, Properties, Key extends keyof Properties> {
    processor: (args: {
        geoId: string;
        properties: Source;
        previous?: { geoId: string; value: Properties[Key] }[];
    }) => {
        geoId: string;
        value: Properties[Key];
    }[];
    aggregator: ({
        values,
    }: {
        values: { value: Properties[Key]; blockPercentageOverlap: number; cellPercentageOverlap: number }[];
    }) => Properties[Key];
}

export interface CensusProperties {
    households: number | null;
    population: number | null;
}

export type CensusH3CellEntry = H3CellEntry<CensusProperties>;

export interface USPSProperties {
    households: number | null;
    zipCodes: string[];
    cityNames: string[];
    homeValues: number;
    income: number;
    age: number;
    percentOwnerOccupied: number;
    percentHouseholdWithChildren: number;
    percentHispanic: number;
    newMoverTotal: number;
    newMoverSFDU: number;
    newMoverMFDU: number;
    businesses: number;
    sfdu: number;
    mfdu: number;
}

export type USPSH3CellEntry = H3CellEntry<USPSProperties>;

export type USPSIndexerPipelineProperty<Key extends keyof USPSProperties> = IndexerPipelineProperty<
    USPSCarrierRoute["properties"],
    USPSProperties,
    Key
>;

export type CustomerDataProperties = {
    customerCount?: number | null;
    customerSales?: number | null;
    customerMarketShare?: number | null;
};

export type CensusAndUSPSProperties = Partial<Omit<CensusProperties, "households">> &
    Partial<Omit<USPSProperties, "households">> & {
        censusHouseholds: number | null;
        uspsHouseholds: number | null;
        consensusHouseholds: number | null;
    };

export type HoneygridAPIH3CellProperties = CensusAndUSPSProperties &
    CustomerDataProperties & { id: string; center: [number, number]; [k: string]: any };

export type HoneyGridFeatureCollection = FeatureCollection<Polygon, HoneygridAPIH3CellProperties>;

export type HoneygridH3CellEntry = H3CellEntry<CensusAndUSPSProperties>;

export enum ReferenceFields {
    CustomerCount = "customerCount",
    CustomerSales = "customerSales",
    CustomerMarketShare = "customerMarketShare",
    Households = "consensusHouseholds",
    HomeValues = "homeValues",
    Population = "population",
    Income = "income",
    Age = "age",
    PercentOwnerOccupied = "percentOwnerOccupied",
    PercentHouseholdWithChildren = "percentHouseholdWithChildren",
    PercentHispanic = "percentHispanic",
    NewMoverTotal = "newMoverTotal",
    NewMoverSFDU = "newMoverSFDU",
    NewMoverMFDU = "newMoverMFDU",
    Businesses = "businesses",
    /**
     * Single-family dwelling units
     */
    SFDU = "sfdu",
    /**
     * Multi-family dwelling units
     */
    MFDU = "mfdu",
}
