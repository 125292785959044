import * as Sentry from "@sentry/react";
import { FC, Suspense, lazy, useEffect } from "react";
import {
    Navigate,
    Route,
    Routes,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

import { useSeedGoogleTagManagerData } from "@/hooks/useSeedGoogleTagManagerData";
import { Routes as NamedRoutes } from "@/routes";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hooks/useAppState";
import { selectors } from "@redux/store";

import { AuthDialog } from "../Auth";
import { ComingSoonDialog } from "../ComingSoonDialog";
import { Loading } from "../Loading";
import { LoginScreen } from "../LoginScreen";
import { SignUpWindow } from "../SignUpWindow";
import { Success } from "../Success/Success";

const Workspace = lazy(() =>
    import("@/components/Workspace").then(({ WorkspaceContainer }) => ({ default: WorkspaceContainer })),
);
const Dashboard = lazy(() => import("@/components/Dashboard").then(({ Dashboard }) => ({ default: Dashboard })));
const Header = lazy(() => import("@/components/Header").then(({ Header }) => ({ default: Header })));

if (import.meta.env.ENVIRONMENT !== "dev" || !import.meta.env.VITEST) {
    Sentry.init({
        dsn: "https://650266998fd9b7aeeb20e1f0d26c6932@o4506351022899200.ingest.sentry.io/4506418883526656",
        integrations: [
            new Sentry.BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
            new Sentry.Replay(),
        ],
        // since we aren't allowing it in dev, we can default to prod
        environment: import.meta.env.ENVIRONMENT ?? "prod",
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/honeygrid-pre-prod\.web\.app\/api/,
            /^https:\/\/honeygrid-8021e\.web\.app\/api/,
            /^https:\/\/api\.mapbox\.com\/v4/,
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

export const App: FC = () => {
    const isComingSoonDialogOpen = useAppSelector(selectors.isComingSoonDialogOpen);
    const isLoginDialogOpen = useAppSelector(selectors.isLoginDialogOpen);

    // Initialize data for Google Tag Manager
    useSeedGoogleTagManagerData();

    const [dispatch, actions] = useAppDispatch();

    const handleDismissComingSoonDialog = () => {
        dispatch(actions.toggleIsComingSoonDialogOpen());
    };

    return (
        <>
            <Box
                sx={{
                    display: "grid",
                    height: "100vh",
                    gridTemplateRows: "auto 1fr",
                    gridTemplateAreas: `"header" "main"`,
                }}
                id="appContainer"
            >
                <Box
                    sx={{
                        gridArea: "header",
                    }}
                >
                    <Header />
                </Box>
                <Box sx={{ gridArea: "main", maxHeight: "calc(100vh - 64px)" }}>
                    <Suspense fallback={<Loading />}>
                        <Routes>
                            <Route path={NamedRoutes.Default} element={<LoginScreen />} />
                            <Route path={NamedRoutes.Dashboard} element={<Dashboard />} />
                            <Route path={NamedRoutes.Success} element={<Success />} />
                            <Route path={NamedRoutes.AcceptInvite} element={<SignUpWindow />} />
                            <Route path=":workspaceId/*" element={<Workspace />} />
                            <Route
                                key={NamedRoutes.WildCard}
                                path={NamedRoutes.WildCard}
                                element={<Navigate to={NamedRoutes.Default} />}
                            />
                        </Routes>
                    </Suspense>
                </Box>
            </Box>
            {isLoginDialogOpen && <AuthDialog open={isLoginDialogOpen} />}
            {isComingSoonDialogOpen && (
                <ComingSoonDialog isOpen={isComingSoonDialogOpen} onDismiss={() => handleDismissComingSoonDialog()} />
            )}
        </>
    );
};
